// Config. ---------------------------------------------------------------------
import packageJson from "../../../package.json";

// =============================================================================
// getPackageVersion...
// =============================================================================
export const getPackageVersion = () => {
  if (process.env.NODE_ENV === "development") {
    return "V" + packageJson.version + "-dev";
  } else {
    return "V" + packageJson.version;
  }
};
