export interface KeycloakCfgType {
  requis: string[];
  defaultLocale: string;
  locale: string[];
  requiredUserActions: string[];
  registrationEmailAsUsername: boolean;
}

export const defaultKeycloakCfg = {
  requis: ["username", "email"],
  defaultLocale: "fr",
  locale: ["en", "fr"],
  registrationEmailAsUsername: false,
  requiredUserActions: [
    "CONFIGURE_TOTP",
    "VERIFY_EMAIL",
    "UPDATE_PASSWORD",
    "UPDATE_PROFILE",
    "update_user_locale",
    "da-otp-sms-required-action",
    "da-secret-question-required-action",
    "TERMS_AND_CONDITIONS",
    "delete_account",
    "webauthn-register",
  ],
};
