import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";

// Functions. ------------------------------------------------------------------
const DEFAULT_LANG = {
  fr: {
    name: "Français",
    dir: "ltr",
  },
};
const i18nGetLangueKey = (index?: number) => {
  const langueList = window.__ENV__.REACT_APP_LANGUE ?? DEFAULT_LANG;
  return Object.keys(langueList);
};
const i18nGetLangueDirection = () => {
  const langueList = window.__ENV__.REACT_APP_LANGUE ?? DEFAULT_LANG;
  const curLangueDirection = {};
  for (const key of Object.keys(langueList)) {
    curLangueDirection[key] = langueList[key].dir;
  }
  return curLangueDirection;
};

// Const. ----------------------------------------------------------------------
export const availableDirection = i18nGetLangueDirection();
export const availableLanguage = i18nGetLangueKey();
export const defaultLanguage = availableLanguage[0];
const curLang = localStorage.getItem("i18nextLng") ?? defaultLanguage;

// interface. ------------------------------------------------------------------
export interface LangueDrawer {
  key: string;
  icon: string;
  name: string;
}

// =============================================================================
// init...
// =============================================================================
i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: curLang,
    supportedLngs: i18nGetLangueKey(),
    ns: ["common", "data", "login", "message", "nav", "partner", "user"],
    defaultNS: "common",
    nsSeparator: ":",
    backend: {
      loadPath: "/languages/{{lng}}/{{ns}}.json",
    },
    debug: process.env.NODE_ENV !== "production",
  });

// =============================================================================
// onLanguageChanged...
// =============================================================================
i18n.on("languageChanged", (lang: string) => {
  localStorage.setItem("i18nextLng", lang);
  document.body.dir = availableDirection[lang];
});

export default i18n;
