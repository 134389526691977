import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { theme } from "@docaposte-agility/da-design-system";
import CssBaseline from "@mui/material/CssBaseline";

// Services/Utils. -------------------------------------------------------------
import {
  executeRequestInterceptor,
  executeResponseInterceptor,
} from "config/axios";

// =============================================================================
// queryClient...
// =============================================================================
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount > 2 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

// =============================================================================
// Providers...
// =============================================================================
const Providers = ({ children }) => {
  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    executeRequestInterceptor();
    executeResponseInterceptor();
  }, []);

  // Render. -------------------------------------------------------------------
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default Providers;
