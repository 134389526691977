import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// useGetKeycloakConfigs...
// =============================================================================
export const useGetKeycloakConfigs = (
  config: UseMutationOptions<{ id_token: string }, AxiosError, {}> = {},
) => {
  const pathname = window.location.pathname;
  // Récupération du tenant dans l'URL car pas encore reçu la config du tenant depuis l'API
  const tenant =
    pathname && pathname.split("/").length >= 2 ? pathname.split("/")[1] : "";
  return useMutation(
    // eslint-disable-next-line no-empty-pattern
    ({}) =>
      Axios.get("/auth/config", {
        headers: { tenant: tenant || "" },
        params: {},
      }),
    {
      ...config,
    },
  );
};
