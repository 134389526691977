import * as React from "react";

// Services/Utils. -------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";
import { dateDay } from "utils/date/dateDay";

// Types/Const. ----------------------------------------------------------------
import {
  UserDetailsType,
  emptyUserDetails,
  UserApplicatifsType,
  emptyUserApplicatifs,
  emptyPatchUserRoles,
  PatchUserRolesListType,
} from "types/user.type";
import {
  emptyPartner,
  emptyPartnerList,
  emptyPartnerApplication,
  PartnerApplicationsType,
  PartnerType,
  PartnerListType,
} from "types/partner.type";
import { defaultKeycloakCfg, KeycloakCfgType } from "types/keycloak-cfg.type";
import { PaginationConfigType } from "types/pagination-config.type";

// exported IDP context. -------------------------------------------------------
export const IdpContext = React.createContext({
  contextKeycloak: {} as KeycloakCfgType,
  contextUpdated: {
    userDetails: [] as string[],
    userApplicatifs: [] as PatchUserRolesListType[],
  },
  contextAdminPartner: { id: "" },
  contextPartner: {} as PartnerListType,
  contextApplications: [] as PartnerApplicationsType[],
  contextUserDetails: { id: "" },
  contextUserApplicatifs: [] as UserApplicatifsType[],
  contextNomenclature: [] as PaginationConfigType[],
  contextLockedValue: false,
  setContextLockedValue: (value: boolean) => {},
  setContextNomenclature: (value: PaginationConfigType[]) => {},
  setContextKeycloak: (keycloakCfg: KeycloakCfgType) => null,
  setContextAdminPartner: (ctxtAdminPartner: PartnerType) => null,
  setContextPartner: (ctxtPartner: PartnerListType) => null,
  setContextApplications: (ctxtApplication: PartnerApplicationsType[]) => null,
  setContextUserDetails: (mode: string, data?: UserDetailsType) => null,
  setContextUserApplicatifs: (mode: string, data?: UserApplicatifsType[]) =>
    null,
  resetUser: () => null,
});

// =============================================================================
// Contexts...
// =============================================================================
interface ContextProviderProps {
  children: React.ReactNode;
}
const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  // Members. ------------------------------------------------------------------
  const [contextKeycloak, setCtxtKeycloak] =
    React.useState<KeycloakCfgType>(defaultKeycloakCfg);

  const [contextPartner, setCtxtPartner] =
    React.useState<PartnerListType>(emptyPartnerList);

  const [contextAdminPartner, setCtxtAdminPartner] =
    React.useState<PartnerType>(emptyPartner);

  const [contextApplications, setCtxtApplications] = React.useState<
    PartnerApplicationsType[]
  >(emptyPartnerApplication);

  const [contextUserDetails, setCtxtUserDetails] =
    React.useState<UserDetailsType>(emptyUserDetails);
  const [boUserDetails, setBoUserDetails] =
    React.useState<UserDetailsType>(emptyUserDetails);

  const [contextLockedValue, setContextLockedValue] = React.useState(false);

  const [contextUserApplicatifs, setCtxtUserApplicatifs] =
    React.useState<UserApplicatifsType[]>(emptyUserApplicatifs);
  const [boUserApplicatifs, setBoUserApplicatifs] =
    React.useState<UserApplicatifsType[]>(emptyUserApplicatifs);

  const [contextUpdated, setCtxtUpdated] = React.useState({
    userDetails: [] as string[],
    userApplicatifs: [] as PatchUserRolesListType[],
  });
  const [contextNomenclature, setCtxtNomenclature] = React.useState<
    PaginationConfigType[]
  >([]);

  // Functions. ----------------------------------------------------------------
  const setContextNomenclature = (ctxtNomenclature: PaginationConfigType[]) => {
    console.debug(dateLog("CTX", "setContextNomenclature()"), ctxtNomenclature);
    setCtxtNomenclature(ctxtNomenclature);
    return null;
  };

  const setContextKeycloak = (ctxtKeycloak: KeycloakCfgType) => {
    console.debug(dateLog("CTX", "setContextKeycloak()"), ctxtKeycloak);
    setCtxtKeycloak(ctxtKeycloak);
    return null;
  };

  const setContextPartner = (ctxtPartner: PartnerListType) => {
    console.debug(dateLog("CTX", "setContextPartner()"), ctxtPartner);
    setCtxtPartner(ctxtPartner);
    return null;
  };

  const setContextAdminPartner = (ctxtAdminPartner: PartnerType) => {
    console.debug(dateLog("CTX", "setContextAdminPartner()"), ctxtAdminPartner);
    setCtxtAdminPartner(ctxtAdminPartner);
    return null;
  };
  const setContextApplications = (
    ctxtApplication: PartnerApplicationsType[],
  ) => {
    console.debug(dateLog("CTX", "setContextApplication()"), ctxtApplication);
    setCtxtApplications(ctxtApplication);
    return null;
  };

  const setContextUserDetails = (mode: string, data?: UserDetailsType) => {
    console.debug(dateLog("CTX", "setContextUserDetails(" + mode + ")"), data);
    if (mode === "init") {
      setCtxtUpdated({
        userDetails: [],
        userApplicatifs: contextUpdated.userApplicatifs,
      });
      setCtxtUserDetails(data || emptyUserDetails);
      setBoUserDetails(data || emptyUserDetails);
    } else if (mode === "update") {
      checkForUpdateDetails(data || emptyUserDetails);
    }
    return null;
  };
  const checkForUpdateDetails = (data: UserDetailsType) => {
    if (contextUserDetails.id !== "") {
      const updatedDetail: string[] = [];

      Object.entries(emptyUserDetails).forEach(([key]) => {
        const isDateKey =
          key === "deactivationDate" || key === "activationDate";
        const boUserDetailsValue = boUserDetails[key];
        const dataValue = data[key];
        if (
          (isDateKey &&
            dateDay(boUserDetailsValue ?? "") !== dateDay(dataValue ?? "")) ||
          JSON.stringify(boUserDetailsValue) !== JSON.stringify(dataValue)
        ) {
          updatedDetail.push(key);
        }
      });

      setCtxtUpdated({
        userDetails: updatedDetail,
        userApplicatifs: contextUpdated.userApplicatifs,
      });
    }
    setCtxtUserDetails(data);
  };

  const setContextUserApplicatifs = (
    mode: string,
    data?: UserApplicatifsType[],
  ) => {
    console.debug(
      dateLog("CTX", "setContextUserApplicatifs(" + mode + ")"),
      data,
    );
    if (mode === "init") {
      setCtxtUpdated({
        userDetails: contextUpdated.userDetails,
        userApplicatifs: [],
      });
      setCtxtUserApplicatifs(data || emptyUserApplicatifs);
      setBoUserApplicatifs(data || emptyUserApplicatifs);
    } else if (mode === "update") {
      checkForUpdateApplicatif(data || emptyUserApplicatifs);
    }
    return null;
  };
  const checkForUpdateApplicatif = (data: UserApplicatifsType[]) => {
    if (contextUserDetails.id !== "") {
      const updatedApplicatif: PatchUserRolesListType[] = [];

      // add/remove dans un applicatif.  ---------------------------------------
      data.forEach((item) => {
        const found = boUserApplicatifs.find((item2) => item2.id === item.id);
        if (!found) {
          updatedApplicatif.push({
            id: item.id,
            type: "PATCH",
            roles: {
              add: item.roles.map((role) => role.id),
              remove: [],
            },
          });
        } else {
          const itemRolesList = item.roles.map((role) => role.id);
          const foundRolesList = found.roles.map((role) => role.id);
          const added = itemRolesList.filter(
            (elem) => foundRolesList.indexOf(elem) === -1,
          );
          const removed = foundRolesList.filter(
            (elem) => itemRolesList.indexOf(elem) === -1,
          );
          if (added.length > 0 || removed.length > 0) {
            updatedApplicatif.push({
              id: item.id,
              type: "PATCH",
              roles: {
                add: added,
                remove: removed,
              },
            });
          }
        }
      });

      // Delete de tout les roles d'un applicatif. -----------------------------
      boUserApplicatifs.forEach((item) => {
        const found = data.find((item2) => item2.id === item.id);
        if (found === undefined) {
          updatedApplicatif.push({
            id: item.id,
            type: "DEL",
            roles: emptyPatchUserRoles,
          });
        }
      });

      // Set context updated fields. -------------------------------------------
      setCtxtUpdated({
        userDetails: contextUpdated.userDetails,
        userApplicatifs: updatedApplicatif,
      });
    }
    setCtxtUserApplicatifs(data);
  };

  const resetUser = () => {
    console.debug(dateLog("CTX", "resetUser()"));
    setCtxtUserDetails(emptyUserDetails);
    return null;
  };

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    console.debug(dateLog("CTX", "contextUpdated()"), contextUpdated);
  }, [contextUpdated]);

  // Provider. -----------------------------------------------------------------
  return (
    <IdpContext.Provider
      value={{
        contextKeycloak,
        contextPartner,
        contextAdminPartner,
        contextApplications,
        contextUpdated,
        contextUserDetails,
        contextUserApplicatifs,
        contextNomenclature,
        contextLockedValue,
        setContextLockedValue,
        setContextNomenclature,
        setContextKeycloak,
        setContextPartner,
        setContextAdminPartner,
        setContextApplications,
        setContextUserDetails,
        setContextUserApplicatifs,
        resetUser,
      }}
    >
      {children}
    </IdpContext.Provider>
  );
};
export default ContextProvider;
