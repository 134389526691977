import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Docaposte agility. ----------------------------------------------------------
import { ProtectedRoute } from "@docaposte-agility/da-react-keycloak";

// Components. -----------------------------------------------------------------
import Home from "app/pages/Home";
import Loading from "app/pages/Loading";
import ContactAdministrator from "app/pages/ContactAdministrator";

// Types/Const. ----------------------------------------------------------------
import { Roles } from "types/roles.enum";

// Lazy loading. ---------------------------------------------------------------
const ManageUser = React.lazy(() => import("app/pages/manage/ManageUser"));
const AdminPartner = React.lazy(() => import("app/pages/admin/AdminPartner"));

// =============================================================================
// Router...
// =============================================================================
const Router = () => {
  // Render. -------------------------------------------------------------------
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          {/* Home. -------------------------------------------------------- */}
          <Route path="/:tenant" element={<Home />} />

          {/* SuperAdmin. -------------------------------------------------- */}
          <Route
            path="/:tenant/admin/partner/:partnerPathId?/:userPathId?"
            element={
              <ProtectedRoute
                component={AdminPartner}
                roles={[Roles.SUPER_ADMIN]}
                fallbackComponent={ContactAdministrator}
                loaderComponent={Loading}
              />
            }
          />

          {/* Gestionnaire. ------------------------------------------------ */}
          <Route
            path="/:tenant/manage/user/:userPathId?"
            element={
              <ProtectedRoute
                component={ManageUser}
                roles={[Roles.GESTIONNAIRE]}
                fallbackComponent={ContactAdministrator}
                loaderComponent={Loading}
              />
            }
          />

          {/* Other. ------------------------------------------------------- */}
          <Route
            path="*"
            element={
              <ContactAdministrator
                type="contactAdmin"
                mobileImg="images/actionConnect.png"
              />
            }
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};
export default Router;
