import React from "react";
import { Navigate, useParams } from "react-router-dom";

// Docaposte agility. ----------------------------------------------------------
import { useKeycloak } from "@docaposte-agility/da-react-keycloak";

// Components. -----------------------------------------------------------------
import ContactAdministrator from "app/pages/ContactAdministrator";

// Utils. ----------------------------------------------------------------------
import { getStorageValue } from "utils/memoryStorage";

// Types/Const. ----------------------------------------------------------------
import { Roles } from "types/roles.enum";

// =============================================================================
// Home...
// =============================================================================
interface HomeProps {}
const Home: React.FC<HomeProps> = () => {
  // Members. ------------------------------------------------------------------
  const { logout, authenticated, hasResourceRole } = useKeycloak();
  const params = useParams();

  // Functions. ----------------------------------------------------------------
  const getDefaultPath = () => {
    if (hasResourceRole(Roles.SUPER_ADMIN)) {
      return "/admin/partner";
    } else if (hasResourceRole(Roles.GESTIONNAIRE)) {
      return "/manage/user";
    } else {
      return "";
    }
  };

  // Not authenticated. --------------------------------------------------------
  if (!authenticated) {
    return null;
  }

  // Render when authenticated. ------------------------------------------------
  const pathFromRole =
    getStorageValue("route") !== null && getStorageValue("route") !== "/"
      ? getStorageValue("route")
      : getDefaultPath();

  // SUPER_ADMIN ou GESTIONNAIRE. ----------------------------------------------
  if (
    hasResourceRole(Roles.SUPER_ADMIN) ||
    hasResourceRole(Roles.GESTIONNAIRE)
  ) {
    return <Navigate to={`/${params.tenant}${pathFromRole}`} replace={true} />;
  }

  // Unauthorized... -----------------------------------------------------------
  return (
    <ContactAdministrator
      type="unauthorized"
      mobileImg="images/stop.png"
      logout={logout}
    />
  );
};
export default Home;
