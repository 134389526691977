// Utils. ----------------------------------------------------------------------
import { dateFormat } from "utils/date/dateFormat";

// =============================================================================
// dateDay: convert to day date format (ISOString): "yyyy-MM-ddThh:mm:ss.sssZ"
// =============================================================================
export const dateDay = (isoDate: string, dayTime?: "start" | "end" | "now") => {
  switch (dayTime) {
    case "start":
      return dateFormat(isoDate, "yyyy-MM-ddT00:00:00.000Z");
    case "end":
      return dateFormat(isoDate, "yyyy-MM-ddT23:59:59.999Z");
    case undefined:
      // isoDate.split("T")[0]: Pour ne pas prendre les heures...
      return dateFormat(isoDate.split("T")[0], "yyyy-MM-dd");
    default: // normalement on ne passera jamais dans le default
      return isoDate;
  }
};
