// =============================================================================
// UserType...
// =============================================================================
export interface UserType {
  details: UserDetailsType;
  applicatifs: UserApplicatifsType[];
}
export interface UserDetailsType {
  id: string;
  created: string;
  username: string;
  lastName: string;
  firstName: string;
  email: string;
  locale: string;
  lastLogin: string;
  requiredActions: string[];
  enabled: boolean;
  locked: boolean;
  emailVerified: boolean;
  activationDate: string | null;
  deactivationDate: string | null;
  attributes: UserAttributesType[];
}
export interface UserAttributesType {
  key: string;
  value: string;
}
export interface UserApplicatifsType {
  id: string;
  clientId: string;
  name?: string;
  roles: UserRolesType[];
  deletable: boolean;
  groupRoles?: UserRolesType[];
}
export interface UserRolesType {
  id: string;
  name: string;
}

// =============================================================================
// emptyUser...
// =============================================================================
export const emptyUserDetails: UserDetailsType = {
  id: "",
  created: "",
  username: "",
  lastName: "",
  email: "",
  locale: "",
  firstName: "",
  lastLogin: "",
  requiredActions: [],
  enabled: false,
  locked: false,
  emailVerified: false,
  activationDate: null,
  deactivationDate: null,
  attributes: [],
};
export const emptyUserAttributes = {
  key: "",
  value: "",
};
export const emptyUserApplicatifs = [] as UserApplicatifsType[];
export const emptyUserRoles = [] as UserRolesType[];
export const emptyUser = {
  details: emptyUserDetails,
  applicatifs: emptyUserApplicatifs,
};

// =============================================================================
// PatchUserRolesType...
// =============================================================================
export interface PatchUserRolesListType {
  id: string;
  type: string;
  roles: PatchUserRolesType;
}
export interface PatchUserRolesType {
  add: string[];
  remove: string[];
}
export const emptyPatchUserRoles = {
  add: [],
  remove: [],
};
