import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Utils. ----------------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// Types/Const. ----------------------------------------------------------------
import { MOBILE_MAX_WIDTH } from "types/idp-const";

// =============================================================================
// ContactAdministrator...
// =============================================================================
interface ContactAdministratorProps {
  type?: string;
  mobileImg?: string;
  logout?: () => void;
}
const ContactAdministrator: React.FC<ContactAdministratorProps> = ({
  type = "unauthorized",
  mobileImg = "images/stop.png",
  logout,
}) => {
  // Members. ------------------------------------------------------------------
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: isMobile
          ? "none"
          : `url("/images/background.png") center center no-repeat fixed`,
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
        outline: 0,
      }}
    >
      {isMobile && (
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "2rem" }}
        >
          <img
            src={mobileImg}
            style={{
              border: "none",
              marginRight: "10px",
              marginLeft: "10px",
              height: "160px",
              pointerEvents: "none",
            }}
            alt="contact-admin"
          />
        </Grid>
      )}

      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs
      >
        <Paper
          elevation={isMobile ? 0 : 3}
          sx={{
            width: "500px",
            flex: "0 auto",
            p: "5px",
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ height: "100%" }}
          >
            {/* Title. ------------------------------------------------------- */}
            <Grid item>
              <Typography
                variant="h1"
                sx={{
                  p: "1rem",
                  textAlign: isMobile ? "start" : "center",
                }}
              >
                {isMobile
                  ? t("common:produit")
                  : t("message:" + type + ".title")}
              </Typography>
            </Grid>
            <Divider sx={{ backgroundColor: "cobalt.grey10" }} />

            {/* Message. --------------------------------------------------- */}
            <Grid item sx={{ p: "2rem 1rem" }}>
              <Typography
                variant="h2"
                sx={{ textAlign: "center", color: "cobalt.lynch" }}
              >
                {parseContent(
                  isMobile
                    ? t("message:" + type + ".messageMobile")
                    : t("message:" + type + ".messageDesktop"),
                )}
              </Typography>
            </Grid>

            {/* Action. ------------------------------------------------------ */}
            {logout !== undefined && (
              <>
                <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
                <Grid
                  item
                  sx={{ p: "1rem" }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={logout}
                    sx={{
                      textAlign: "center",
                      width: isMobile ? "100%" : "auto",
                    }}
                  >
                    {t("message:" + type + ".button")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>

      {/* Logo. ------------------------------------------------------------ */}
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-top"
        alignItems="flex-end"
        sx={{ height: "40px", p: "0px 10px" }}
      >
        <img
          src="/images/Tech-Trust.svg"
          style={{
            border: "none",
            height: "30px",
            pointerEvents: "none",
          }}
          alt="logo-docaposte"
        />
      </Grid>
    </Grid>
  );
};
export default ContactAdministrator;
