import React from "react";
import { Grid, CircularProgress, useMediaQuery } from "@mui/material";

// Types/Const. ----------------------------------------------------------------
import { MOBILE_MAX_WIDTH } from "types/idp-const";

// =============================================================================
// Loading...
// =============================================================================
interface LoadingProps {}
const Loading: React.FC<LoadingProps> = () => {
  // Members. ------------------------------------------------------------------
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);
  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: isMobile
          ? "none"
          : `url("/images/background.png") center center no-repeat fixed`,
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
        outline: 0,
      }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs
      >
        <CircularProgress
          size="30vh"
          thickness={1.5}
          sx={{ color: "cobalt.lynch" }}
        />
      </Grid>

      {/* Logo. ------------------------------------------------------------ */}
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-top"
        alignItems="flex-end"
        xs={isMobile ? "auto" : undefined}
        sx={{ m: "0rem", p: "0.5rem 1rem" }}
      >
        <img
          src="/images/docaposte.png"
          style={{ border: "none", height: "3rem", pointerEvents: "none" }}
          alt="logo-docaposte"
        />
      </Grid>
    </Grid>
  );
};
export default Loading;
