// =============================================================================
// PartnerType...
// =============================================================================
export interface PartnerType {
  id: string;
  label?: string;
}
export interface PartnerListType {
  activeId: string;
  partnerList: PartnerType[];
}
export interface PartnerApplicationsType {
  id: string;
  clientId: string;
  name?: string;
  roles: PartnerApplicationsRolesType[];
  deletable: boolean;
  groupRoles?: PartnerApplicationsRolesType[];
}
export interface PartnerApplicationsRolesType {
  id: string;
  name: string;
  assignable: boolean;
}

// =============================================================================
// emptyPartner...
// =============================================================================
export const emptyApplicationsRolesType = [] as PartnerApplicationsRolesType[];
export const emptyPartnerApplication = [] as PartnerApplicationsType[];
export const emptyPartnerList = { activeId: "", partnerList: [] };
export const emptyPartner = {
  id: "",
  label: "",
};
