import * as React from "react";

// Components. -----------------------------------------------------------------
import Toaster from "app/components/common/Toaster";

// Types/Const. ----------------------------------------------------------------
import { ToasterType, emptyToaster } from "types/toaster.type";

// =============================================================================
// ToasterContext...
// =============================================================================
interface ToasterContextProps {
  toasterMessage: ToasterType;
  setToasterMessage: (toaster: ToasterType) => void;
}
export const ToasterContext = React.createContext<ToasterContextProps>({
  toasterMessage: emptyToaster,
  setToasterMessage: () => undefined,
});

// =============================================================================
// ToasterProvider...
// =============================================================================
interface ToasterProviderProps {
  children: React.ReactNode;
}
const ToasterProvider: React.FC<ToasterProviderProps> = ({ children }) => {
  const [toasterMessage, setToasterMessage] =
    React.useState<ToasterType>(emptyToaster);

  // Hooks. --------------------------------------------------------------------
  const value = React.useMemo(
    () => ({
      toasterMessage,
      setToasterMessage,
    }),
    [toasterMessage],
  );

  // Provider. -----------------------------------------------------------------
  return (
    <ToasterContext.Provider value={value}>
      {toasterMessage.type !== "disabled" && (
        <Toaster message={toasterMessage} />
      )}
      {children}
    </ToasterContext.Provider>
  );
};
export default ToasterProvider;
