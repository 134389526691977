import React from "react";

// Components. -----------------------------------------------------------------
import Backdrop from "app/components/common/Backdrop";

// =============================================================================
// LoadingContext...
// =============================================================================
interface LoadingContextProps {
  loading: boolean;
  setLoading: (isDisplay: boolean) => void;
}
export const LoadingContext = React.createContext<LoadingContextProps>({
  loading: false,
  setLoading: () => undefined,
});

// =============================================================================
// LoadingProvider...
// =============================================================================
interface LoadingProviderProps {
  children: React.ReactNode;
}
const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  // Hooks. --------------------------------------------------------------------
  const value = React.useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading],
  );

  // Provider. -----------------------------------------------------------------
  return (
    <LoadingContext.Provider value={value}>
      {loading && <Backdrop loading={loading} />}
      {children}
    </LoadingContext.Provider>
  );
};
export default LoadingProvider;
