import React from "react";

// Docaposte agility. ----------------------------------------------------------
import { useKeycloak } from "@docaposte-agility/da-react-keycloak";
import { KEYCLOAK_TOKEN } from "types/idp-const";

// =============================================================================
// useSaveToken...
// =============================================================================
const useSaveToken = (
  refresh: boolean,
  setRefreshToken: (status: boolean) => void,
) => {
  // Members. ------------------------------------------------------------------
  const { token } = useKeycloak();

  // Save routePath. ----------------------------------------------------------
  React.useEffect(() => {
    if (token) {
      window.sessionStorage.setItem(KEYCLOAK_TOKEN, String(token));
      setRefreshToken(false);
    } else {
      window.sessionStorage.removeItem(KEYCLOAK_TOKEN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, token]);
};
export default useSaveToken;
