// Components. -----------------------------------------------------------------
import Providers from "app/providers/Providers";
import LoginProvider from "app/providers/LoginProvider";
import IdpProvider from "app/providers/IdpProvider";
import Router from "app/Router";

// Config. ---------------------------------------------------------------------
import "config/i18n";

// Utils. ----------------------------------------------------------------------
import { dateFormat } from "utils/date/dateFormat";
import { setStorageValue, getStorageValue } from "utils/memoryStorage";
import { getPackageName } from "utils/package/getPackageName";
import { getPackageVersion } from "utils/package/getPackageVersion";

// =============================================================================
// App...
// =============================================================================
function App() {
  // Gestion du tenant et route dans le StorageValue. --------------------------
  const windowRoute = window.location.href;
  if (windowRoute.indexOf("#state=") === -1) {
    const routeFromPathnameSplit = window.location.pathname.split("/");
    const tenantPathname = routeFromPathnameSplit.splice(1, 1)[0];
    const routeFromPathname = routeFromPathnameSplit.join("/");
    setStorageValue(
      "route",
      routeFromPathnameSplit.length > 1 ? routeFromPathname : undefined,
    );
    if (tenantPathname !== getStorageValue("tenant")) {
      setStorageValue("tenant", tenantPathname);
    }
  }

  // Display versions. ---------------------------------------------------
  console.log(
    dateFormat(new Date().toISOString(), "[DA] yyyy-MM-dd hh:mm:ss ") +
      getPackageName() +
      " - " +
      getPackageVersion(),
  );

  // Remove debug trace into production mode. ----------------------------------
  if (process.env.NODE_ENV === "production") {
    console.debug = (...args) => {};
  }
  // render. -------------------------------------------------------------------
  return (
    <Providers>
      <LoginProvider>
        <IdpProvider>
          <Router />
        </IdpProvider>
      </LoginProvider>
    </Providers>
  );
}

export default App;
