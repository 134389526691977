// =============================================================================
// ToasterType interface...
// =============================================================================
export interface ToasterType {
  type: ToasterMessageType;
  title: string;
  message: string;
  autoClose?: number;
  toastId?: string;
}
export const emptyToaster = {
  type: "disabled" as ToasterMessageType,
  title: "",
  message: "",
  autoClose: undefined,
  toastId: undefined,
};

// =============================================================================
// Toaster type...
// =============================================================================
export type ToasterMessageType =
  | "info"
  | "success"
  | "warning"
  | "error"
  | "default"
  | "disabled";
